import { KeyboardEvent } from "react";
import { maskJs } from "mask-js";

function cep(e: KeyboardEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");
  e.currentTarget.value = value;
  return e;
}

function cellPhone(e: KeyboardEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 15;

  var tel = e.currentTarget.value;

  tel = tel.replace(/\D/g, "");
  tel = tel.replace(/^(\d)/, "($1");
  tel = tel.replace(/(.{3})(\d)/, "$1) $2");
  tel = tel.replace(/(.{10})(\d)/, "$1-$2");

  e.currentTarget.value = tel;

  return e;
}

function phone(e: KeyboardEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 14;

  var tel = e.currentTarget.value;

  tel = tel.replace(/\D/g, "");
  tel = tel.replace(/^(\d)/, "($1");
  tel = tel.replace(/(.{3})(\d)/, "$1) $2");
  tel = tel.replace(/(.{9})(\d)/, "$1-$2");

  e.currentTarget.value = tel;

  return e;
}

function cpf(e: KeyboardEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  value = value.replace(/(\d{3})(\d{1,2})/, "$1-$2");
  value = value.replace(/(-\d{2})\d+?$/, "$1");

  e.currentTarget.value = value;

  return e;
}

function cnpj(e: KeyboardEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "$1.$2");
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  value = value.replace(/(\d{3})(\d)/, "$1/$2");
  value = value.replace(/(\d{4})(\d{1,2})/, "$1-$2");
  value = value.replace(/(-\d{2})\d+?$/, "$1");
  e.currentTarget.value = value;
  return e;
}

function currency(e: KeyboardEvent<HTMLInputElement>, noBrSign?: boolean) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{1})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  if (noBrSign) {
    e.currentTarget.value = value;
    return e;
  }

  e.currentTarget.value = `R$ ${value}`;

  return e;
}

function percentage(e: KeyboardEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  
  e.currentTarget.value = `${value}%`;
  return e;
}

function percentageRacional(e: KeyboardEvent<HTMLInputElement>, onBlur?: boolean) {
  let value = e.currentTarget.value;
  if ( onBlur ) {
    if (e.currentTarget.value.length > 0) {
      value = value.replace(/\%/g, "");
      e.currentTarget.value = `${value}%`;
    }
   return e;
  } 
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{1})$/, "$1,$2");
  e.currentTarget.value = value;
  return e;
}

function card(e: KeyboardEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 19;

  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{4})(?=\1)/g, "$1 ");

  e.currentTarget.value = value;
  return e;
}
function fullDate(e: KeyboardEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 10;

  let value = e.currentTarget.value.replace(/\D/g, "");

  if (value.length > 2) {
    value = `${value.slice(0, 2)}/${value.slice(2)}`;
  }

  if (value.length > 5) {
    value = `${value.slice(0, 5)}/${value.slice(5)}`;
  }

  if (value.length > 10) {
    value = value.slice(0, 10);
  }

  e.currentTarget.value = value;
}


function simpleDate(e: KeyboardEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 7;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})/, "$1/");
  e.currentTarget.value = value;
  return e;
}

function cpf_cnpj(value) {
  if (value.length <= 11) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})/, "$1-$2");
    value = value.replace(/(-\d{2})\d+?$/, "$1");
    return value;
  } else {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1/$2");
    value = value.replace(/(\d{4})(\d{1,2})/, "$1-$2");
    value = value.replace(/(-\d{2})\d+?$/, "$1");
    return value;
  }
}

function phone_format(cellphone) {
  if (cellphone?.length == 10) {
    let mask = maskJs("(99) 9999-9999", cellphone);
    return mask;
  }
  let mask = maskJs("(99) 9 9999-9999", cellphone);
  return mask;
}

function onlyLetters(e: KeyboardEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;
  value = value.replace(/[0-9]/g, "");
  e.currentTarget.value = value;
  return e;
}

function onlyNumbers(e: KeyboardEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  e.currentTarget.value = value;
  return e;
}
function clearMask(value) {
  return value.replace(/[^a-zA-Z0-9 ]/g, "").replaceAll(" ", "");
}

export type MaskTypes =
  | "cep"
  | "currency"
  | "cpf"
  | "cellPhone"
  | "phone"
  | "cnpj"
  | "card"
  | "simpleDate"
  | "clearMask"
  | "cpf_cnpj"
  | "phone_format"
  | "percentage" 
  | "percentageRacional"
  | "onlyLetters"
  | "fullDate"
  | "onlyNumbers"
export {
  cep,
  currency,
  cpf,
  cellPhone,
  phone,
  cnpj,
  card,
  simpleDate,
  clearMask,
  cpf_cnpj,
  phone_format,
  percentage,
  percentageRacional,
  onlyLetters,
  fullDate,
  onlyNumbers,
};
