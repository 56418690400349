export function getPeriodicity(type: string) {
  const Periodicity = {
    MONTHLY: "Mensal",
    SEMESTER: "Semestral",
    YEARLY: "Anual",
  };
  return Periodicity[type] || type;
}

export const getStatus = (status: string) => {
  const Status = {
    SCHEDULED: "Agendadas",
    CANCELED: "Canceladas",
    PAID: "Pago",
    PAID_MANUALLY: "Pago Manualmente",
    LATE: "Atrasado",
    ACTIVE: "Ativo",
    PENDING: "Pendente",
    AUTHORIZED: "Autorizado",
    FAILED: "Erro",
    INACTIVE: "Inativo",
    ABANDONED: "Desistência",
    DEFAULTER: "Inadimplente",
    CHARGEBACK: "Contestada",
    REFUND: "Estornada",
    DISPUTE: "Em disputa"
  };
  return Status[status] || status;
};

export const getNextLetter = (letter: string) => {
  if (typeof letter !== 'string' || letter.length !== 1 || !/[a-zA-Z]/.test(letter)) {
      throw new Error("Input must be a single alphabetical character.");
  }
  if (letter === 'z') {
      return 'a';
  } else if (letter === 'Z') {
      return 'A';
  } else {
      return String.fromCharCode(letter.charCodeAt(0) + 1);
  }
}
export const getColorBadgeStatus = (status: string) => {
  const BadgeStatus = {
    SCHEDULED: "yellow",
    CANCELED: "lightred",
    PAID: "green",
    PAID_MANUALLY: "green",
    LATE: "yellow",
    ACTIVE: "lightgreen",
    PENDING: "yellow",
    AUTHORIZED: "blue",
    FAILED: "red",
    INACTIVE: "gray",
    ABANDONED: "volcano",
    DEFAULTER: "volcano",
    PROCESS_CANCEL: "lightred",
    SEND_INVOICE_SYSTEM: "yellow",
    SEND_AUTHORIZED: "yellow",
    PROCESS_AUTHORIZED: "yellow",
    CHARGEBACK: "yellow",
    REFUND: "yellow",
    CHARGEBACK_LIST: "yellowDark",
    DISPUTE: "lightPink"
  };
  return BadgeStatus[status] || "gray";
};

export const getColorsBillsStatus = (status: string) => {
  const BillsStatus = {
    Aprovado: "blueBills",
    Pagamentoagendado: "blueBills",
    Aprovar: "yellowBills",
    Reprovado: "orangeBills",
    Atualizaçãonecessária: "yellowBills",
    Erronopaamento: "redBills",
    Saldoinsulficiente: "orangeBills",
    Paga: "greenBills",
    Cancelada: "orangeBills"
  }
  return BillsStatus[status] || "black"
}

export const getPaymentMethod = (type: string) => {
  const PaymentMethod = {
    CREDIT_CARD: "Cartão de Crédito",
    CREDIT_CARD_EARLY_SELLER: "Cartão de Crédito",
    CREDIT_CARD_EARLY_BUYER: "Cartão de Crédito",
    CREDIT_CARD_EARLY_MIXED: "CREDIT_CARD_EARLY_MIXED",
    BANK_SLIP: "Boleto",
    PIX: "Pix",
  };
  return PaymentMethod[type] || type;
};

export const getInvoiceStatus = (status: string) => {
  const Status = {
    AUTHORIZED: "Emitida",
    FAILED: "Erro",
    NOT_EMITTED: "Não Emitida",
    PROCESS_CANCEL: "Cancelamento em processo",
    CANCELED: "Cancelada",
    SEND_INVOICE_SYSTEM: "Emissão em processamento",
    SEND_AUTHORIZED: "Emissão em processamento",
    PROCESS_AUTHORIZED: "Emissão em processamento",
  };

  return Status[status] || "Não Emitida";
};

export const getOperationMethod = (type?: string) => {
  const OperationMethod = {
    DEBIT_TRANSFER: "Repasse",
    INSTALLMENTS_ANTICIPATION: "Parcela de antecipação",
    DEBIT_CORRECTION: "Correção",
    CREDIT_CORRECTION: "Correção",
    FEE_DISCOUNT: "Desconto em taxas",
    REVENUE_ANTICIPATION: "Antecipação de receita",
  };
  return OperationMethod[type] || type;
};

export const getInitials = (value: string) => {
  if (value === "") {
    return "-";
  }
  const initials = value
    .split(" ")
    .map((w) => w[0])
    .join("")
    .substring(0, 2);
  return initials;
};

export function capitalizeFirstLetter(str) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export function formatWhiteLabelNames(str) {
  const whitelabels = {
    parcelamais: "Parcela mais",
    azp: "AZ Pague",
    flipsaude: "Quero flip",
    appbrlcard: "Brl",
    bsbpaycredit: "BSB Pay",
    bmhealthinvest: "BM Invest",
    stanleysveins: "Stanley's Veins"
  }
  return whitelabels[str] || capitalizeFirstLetter(str);
}

export function formatCep(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");
  return value;
}