export const formatDate = (date: string | Date, shortDate?: boolean) => {
  if (!date) return "";

  if (shortDate) {
    return new Date(
      `${date.toLocaleString().replace(/-/g, "/")} 12:00:00`
    ).toLocaleDateString("pt-BR", {
      timeZone: "America/Sao_Paulo",
      day: "2-digit",
      month: "2-digit",
    });
  }
  return new Date(
    `${date.toLocaleString().replace(/-/g, "/")} 12:00:00`
  ).toLocaleDateString("pt-BR", {
    timeZone: "America/Sao_Paulo",
  });
};

export function formatTextDate(dateStr) {
  let [day, month, year] = dateStr.split('/');
  let formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function formatDateText(dateStr) {
  let [year, month, day] = dateStr.split('-');
  let formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}