const escapeRegExpMatch = function (s) {
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
};

export const isExactMatch = (str, match) => {
  return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
};

export function isExactRole(userRoles, featureRoles) {
  for (let i = 0; i < featureRoles?.length; i++) {
    if(userRoles?.includes(featureRoles[i])) {
      return true
    }
  }
  return false;
}

export function isExactFeatureToggle(userFeatureToggles: Array<string>, featureToggle: string, inside = true) {
  return userFeatureToggles?.includes(featureToggle);
}

export function isExactAllFeatureToggle(userFeatureToggles: Array<string>, featuresToggles: Array<string>) {
  for (let i = 0; i < featuresToggles?.length; i++) {
    if(userFeatureToggles?.includes(featuresToggles[i])) {
      return true
    }
  }
  return false;
}


// export function isExactFeatureToggle(
//   userFeatureToggles: Array<string>, 
//   featureToggle: string,
//   inside: boolean = true
// ) {
//   if (inside) {
//     return userFeatureToggles.includes(featureToggle);

//   } else {
//     return !userFeatureToggles.includes(featureToggle);
//   }

// }