import { DocumentType } from "@/pages/configuracoes/subsellers/NewSubSeller";

export function FormatDocument(document: string): string {
  const cleanedDocument = document?.replace(/\D/g, '');

  if (cleanedDocument?.length === 11) {
    return cleanedDocument?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else if (cleanedDocument?.length === 14) {
    return cleanedDocument?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
}

export function ReturnDocumentType(document: string): DocumentType {
  const cleanedDocument = document?.replace(/\D/g, '');
  if (cleanedDocument?.length === 11) {
    return "CPF"
  } else if (cleanedDocument?.length === 14) {
    return "CNPJ"
  }
}