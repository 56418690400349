export function filterAndPageableToRequest({ filter, pageable }: any) {
  const filterParams = Object.keys(filter)
    .map((key) => `${key}=${filter[key]}`)
    .join("&");
  const pageableParams = Object.keys(pageable)
    .map((key) => `${key}=${pageable[key]}`)
    .join("&");

  return Object.keys(filterParams).length > 0
    ? `?${filterParams}&${pageableParams}`
    : Object.keys(pageable).length > 0
    ? `?${pageableParams}`
    : "";
}
