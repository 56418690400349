import { showToast } from "@/utils";

export function copyTextToClipboard(text: string, message?: string) {
  if ("clipboard" in navigator) {
    showToast({
      message: message ?? "Link copiado para a área de transferência!",
      type: "info",
    });
    return navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}
