export const formatCurrencyToApi = (value: any) => {
  if (value === "" || undefined || null) return 0;
  if(value?.startsWith("-")) {
    let valueWithoutRs = value?.split("$");
    return parseFloat(valueWithoutRs[1]?.replace(".", "")?.replace(",", ".")) * -1;
  }
  if (!value?.startsWith("R")) {
    return parseFloat(value?.replace(".", "")?.replace(",", "."));
  }
  let valueWithoutRs = value?.split("$");
  return parseFloat(valueWithoutRs[1]?.replace(".", "")?.replace(",", "."));
};
export const formatFromApiToInput = (value: any) => {
  if (value === "" || undefined || null) return "";
  return String(value)?.replace(".", ",");
};
export const formatStringAmountToApi = (value: any) => {
  if (value === "" || undefined || null) return 0;
  return parseFloat(String(value)?.replace(".", ".")?.replace(",", "."));
};