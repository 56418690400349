interface ApplicationNameProps {
    type?: string;
}
export function getApplicationName({ type = "application" }: ApplicationNameProps) {
    if(type === "localhost") {
        return window?.location?.hostname?.split(':')[0]
    }
    if(type === "application") {
        return window?.location?.hostname?.split('.')[1]
    }
}