import { addDays, isMonday, isWednesday, isFriday } from 'date-fns';


export function nextDayOfPaymentWeek(date: Date) {
    const daysToConsider = [1, 3, 5];
    let nextDay = date;

    while (!(daysToConsider.includes(nextDay.getDay()) && (isMonday(nextDay) || isWednesday(nextDay) || isFriday(nextDay)))) {
        nextDay = addDays(nextDay, 1);
    }

    return nextDay;
}

export function smallerBeforeDay(date: Date) {
    const daysToConsider = [1, 3, 5];
    if (daysToConsider.includes(date.getDay())) {
        return date;
    } else {
        let smallerDate = date;

        while (!daysToConsider.includes(smallerDate.getDay())) {
            smallerDate = addDays(smallerDate, -1);
        }
        return smallerDate;
    }
}