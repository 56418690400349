import { nextDayOfPaymentWeek, smallerBeforeDay } from "@/utils/smallerNextDay";
import { format, startOfDay, isToday } from 'date-fns';
import { ptBR } from "date-fns/locale";

interface PaymentForecastInterface {
    dataBills?: Date;
    newBills?: boolean;
}
const holidaysList = ["01/01/2024", '01/02/2024]']

function addDays(date, days) {
    var newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  }
  
export function paymentForecast({ dataBills, newBills }: PaymentForecastInterface) {
    const today = new Date();
    const startToday = startOfDay(new Date());

    let dataBillsFormatGustavo = format(dataBills, 'yyyy-MM-dd');
    const expireDate = new Date(dataBillsFormatGustavo + "T03:00:00Z");
    const maxHoursOfCreate = newBills ? 12: 10;
    const dayOfPayment = smallerBeforeDay(expireDate);

    const shouldIncrementDate = isToday(dayOfPayment) && today.getHours() >= maxHoursOfCreate && today.getMinutes() > 0;

    if (startToday > dayOfPayment || shouldIncrementDate) {
        const newPaymentDateNext = addDays(today, 1);
        const newPaymentDate = nextDayOfPaymentWeek(newPaymentDateNext);
        return format(newPaymentDate, 'MM/dd/yyyy', { locale: ptBR });
    }

    if (today < expireDate) {
        const newPaymentDate = smallerBeforeDay(expireDate);
        return format(newPaymentDate, 'MM/dd/yyyy', { locale: ptBR });
    }

    if (isToday(dayOfPayment) && today.getHours() < maxHoursOfCreate) {
        const newPaymentDate = nextDayOfPaymentWeek(today);
        return format(newPaymentDate, 'MM/dd/yyyy', { locale: ptBR });
    }
}
