interface IFormatCurreny {
  value: number;
  locale?: string | string[];
  style?: string;
}
export const formatPercentage = ({
  locale = "pt-BR",
  value,
  style = "percent"
}: IFormatCurreny) => {
  if (typeof value !== "number") {
    parseFloat(value);
  }

  return new Intl.NumberFormat(locale, {
    style,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
}).format(value / 100);
}