import QRCode from 'qrcode-generator';

export const qrCode = (value: string) => {
    const qr = QRCode(0, 'L');
    qr.addData(value);
    qr.make();
    const cellSize = 1;
    const margin = 4;
    const viewBox = `${-margin} ${-margin} ${qr.getModuleCount() * cellSize + margin * 2} ${qr.getModuleCount() * cellSize + margin * 2}`;
    return (
      <svg width={120} height={120} viewBox={viewBox}>
        {Array.from({ length: qr.getModuleCount() }, (_, y) => {
          return Array.from({ length: qr.getModuleCount() }, (_, x) => {
            if (qr.isDark(x, y)) {
              return <rect key={`${x}-${y}`} x={x * cellSize} y={y * cellSize} width={cellSize} height={cellSize} fill="black" />;
            } else {
              return null;
            }
          });
        })}
      </svg>
    )
}