import { Charge } from "@/models";
import {
  formatDate,
  formatCurrency,
  getStatus,
  getPaymentMethod,
} from "@/utils";

export const formatCharge = (data: Charge) => ({
  ...data,
  formattedExpirationDate: formatDate(data.expirationDate),
  formattedPaidDate: data.paidDate ? formatDate(data.paidDate) : null,
  expirationDate: data.expirationDate,
  formattedStatus: getStatus(data.status),
  formattedInterestAmount: formatCurrency({value: Number(data.interestAmount)}),
  status: data.status,
  value: data.value,
  formattedValue: formatCurrency({ value: Number(data.value) }),
  paymentMethod: getPaymentMethod(data.paymentMethod),
  chargesTries: data.chargesTries.map((charge) => ({
    errorTransactionalResponse: charge.errorTransactionalResponse,
    errorTimestamp: charge.errorTimestamp,
    uuid: charge.uuid,
    formattedExpirationDate: formatDate(charge.expirationDate),
    expirationDate: charge.expirationDate,
    status: charge.status,
    formattedValue: formatCurrency({ value: Number(charge.value) }),
    value: charge.value,
    createdAt: charge?.createdAt
  })),
});
