import { toast } from "react-toastify";

interface ToastProps {
  message: string;
  type?: "warning" | "success" | "error" | "info" | "default";
}

export const showToast = ({ message, type = "error" }: ToastProps) =>
  toast(message, {
    type,
  });
