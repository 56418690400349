import { format } from "date-fns";
import { Subscription } from "../models";
import {
  formatDate,
  formatCurrency,
  getStatus,
  getPaymentMethod,
  getPeriodicity,
} from "../utils";

export const formatSubscription = (data: Subscription) => ({
  ...data,
  formattedStartDate: formatDate(data.startDate),
  startDate: data.startDate,
  formattedStatus: getStatus(data.status),
  status: data.status,
  basicValue: {
    formattedType: getPeriodicity(data.basicValue.type),
    type: data.basicValue.type,
    formattedValue: formatCurrency({ value: Number(data.basicValue.value) }),
    value: data.basicValue.value,
    formattedValuePerMonth: formatCurrency({
      value: Number(data.basicValue.valuePerMonth),
    }),
    valuePerMonth: data.basicValue.valuePerMonth,
  },
  paymentMethod: getPaymentMethod(data.paymentMethod),
  charges: data.charges.map((charge) => ({
    uuid: charge.uuid,
    formattedExpirationDate: formatDate(charge.expirationDate),
    expirationDate: charge.expirationDate,
    status: charge.status,
    formattedValue: formatCurrency({ value: Number(charge.value) }),
    value: charge.value,
  })),
  cancelledDate: data?.cancelledDate && format(new Date(`${data?.cancelledDate}T21:00:00`), "dd/MM/yyyy")
});
